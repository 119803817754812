import {
    FIRST_PARAGRAPH,
    SECOND_PARAGRAPH,
    THIRD_PARAGRAPH,
    FOURTH_PARAGRAPH,
    FIFTH_PARAGRAPH,
    SIXTH_PARAGRAPH,
    IUSPAK_MOBILE_TITLE,
    IUSPAK_MOBILE_FIRST_PARAGRAPH,
} from './strings';

import navigatorLanguage from '../../../utilities/language';
const lang = navigatorLanguage();

const strings = () => ({
    FIRST_PARAGRAPH: FIRST_PARAGRAPH[lang],
    SECOND_PARAGRAPH: SECOND_PARAGRAPH[lang],
    THIRD_PARAGRAPH: THIRD_PARAGRAPH[lang],
    FOURTH_PARAGRAPH: FOURTH_PARAGRAPH[lang],
    FIFTH_PARAGRAPH: FIFTH_PARAGRAPH[lang],
    SIXTH_PARAGRAPH: SIXTH_PARAGRAPH[lang],
    IUSPAK_MOBILE_TITLE: IUSPAK_MOBILE_TITLE[lang],
    IUSPAK_MOBILE_FIRST_PARAGRAPH1: IUSPAK_MOBILE_FIRST_PARAGRAPH[lang]
});

export default strings;