import FloatingButtonContainer from "../floatButtonContainer";
import { changeLanguage, topES, topEN, colorES, colorEN } from "./Utils";

const ChangeLanguage = ({ top }) => (
    <>
        <FloatingButtonContainer text="ES" onClick={() => changeLanguage("es")} top={topES(top)} color={colorES} />
        <FloatingButtonContainer text="EN" onClick={() => changeLanguage("en")} top={topEN(top)} color={colorEN} />
    </>
);

export default ChangeLanguage;