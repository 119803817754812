import Carrousel from '../../../components/carrousel';
import Header from '../../../components/header';
import PortfolioText from "../../../strings/portfolio/index";
import strings from "../../../strings/portfolio/chFinance";
import langsAndTechsStrings from '../../../strings/langsAndTechs';
import { COMPLEXITIES } from '../../../strings/complexities';
import RectanglesList from '../../../components/rectanglesList';
import ChFinanceImgs from './portfolio_imgs';

const portfolioTexts = PortfolioText();
const chFinanceStrings = strings();
const langAndTechs = langsAndTechsStrings();

const DEVELOPED_IN = [
  langAndTechs.REACT,
  langAndTechs.REDUX,
  langAndTechs.NODEJS,
  langAndTechs.EXPRESS,
  langAndTechs.MONGODB,
  langAndTechs.MONGOOSE,
  langAndTechs.JWT,
];

const ChFinancePortfolio = () => (
  <div className="main__portfolio longText">
    <Header text={portfolioTexts.CHFINANCE} level={2} id="chFinance_portfolio" />
    <Header text={portfolioTexts.DEVELOPED_IN} level={3} />
    <RectanglesList listItems={DEVELOPED_IN} />
    <Header text={portfolioTexts.COMPLEXITY} level={3} />
    <RectanglesList listItems={[{ text: COMPLEXITIES.MEDIUM, link: "#" }]} />
    <Carrousel imgs={ChFinanceImgs} carrouselName="IusPak" />
    <p className="longText">{chFinanceStrings.FIRST_PARAGRAPH}</p>
    <p>{chFinanceStrings.SECOND_PARAGRAPH}</p>
    <p>{chFinanceStrings.THIRD_PARAGRAPH}</p>
    <ul>
      {chFinanceStrings.MODULES_LIST.map((item, index) => (
        <li key={index}>{item}</li>
      ))}
    </ul>
    <p>{chFinanceStrings.FOURTH_PARAGRAPH}</p>
  </div>
);

export default ChFinancePortfolio;
