const BlockLink = ({ text, link }) => (
    <div className="inlineListElement">
        <div className="inlineListElementContainer">
            <a
                className="App-link-List"
                href={link}
                rel="noopener noreferrer"
            >
                {text}
            </a>
        </div>
    </div>
);

export default BlockLink;