const HeaderDefaultStyle = {
    color: "rgb(75 72 64)"
}

const HeaderOne = ({ children, headerStyle, id }) => (<h1 style={headerStyle} id={id}>{children}</h1>);
const HeaderTwo = ({ children, headerStyle, id }) => (<h2 style={headerStyle} id={id}>{children}</h2>);
const HeaderThree = ({ children, headerStyle, id }) => (<h3 style={headerStyle} id={id}>{children}</h3>);

const Header = ({ text, level, headerStyle = HeaderDefaultStyle, id= "" }) => {
    if (level === 1) return (<HeaderOne style={headerStyle} id={id} headerStyle={headerStyle}>{text}</HeaderOne>)
    if (level === 2) return (<HeaderTwo style={headerStyle} id={id} headerStyle={headerStyle}>{text}</HeaderTwo>)
    if (level === 3) return (<HeaderThree style={headerStyle} id={id} headerStyle={headerStyle}>{text}</HeaderThree>)

    return (<HeaderOne style={headerStyle}>{text}</HeaderOne>)
}

export default Header;