import React from "react";
import WarningAboutSiteStrings from "../../strings/warningAboutSite";
import "./styles.css";

const alternativeButtonStyle = {
    padding: "1rem",
    margin: ".5rem",
    cursor: "pointer",
    backgroundColor: "rgb(250, 246, 238)",
    color: "rgb(98, 95,85)"
};

const WarningAboutSite = () => {
    const [show, setShow] = React.useState(true);

    const handleNeverShowAgain = () => {
        localStorage.setItem("neverShowAgainWarningAboutSite", true);
        setShow(false);
    }

    React.useState(() => {
        const neverShowAgainWarningAboutSite = localStorage.getItem("neverShowAgainWarningAboutSite");

        if (neverShowAgainWarningAboutSite) {
            setShow(false);
        }

    }, []);

    if (!show) {
        return null;
    }

    return (
        <div className="warningAboutSiteMainContainer">
            <div style={ {
                padding: "1rem",
                textAlign: "left",
            } }>
                { WarningAboutSiteStrings().WARNING_ABOUT_SITE }
                <a href="http://ivanfuentes.com">Ivan Fuentes</a>
            </div>
            <div style={ {
                display: "flex",
                width: "100%",
                alignContent: "right",
                justifyContent: "right",
            } }>
                <div style={ alternativeButtonStyle }
                    onClick={ handleNeverShowAgain }
                >
                    { WarningAboutSiteStrings().NEVER_SHOW_AGAIN }
                </div>
                <div style={ alternativeButtonStyle }
                    onClick={ () => setShow(false) }
                >
                    { WarningAboutSiteStrings().CLOSE_BUTTON }
                </div>
            </div>

        </div>
    );
};


export default WarningAboutSite;