const FloatingButtonContainer = ({ text, onClick, top, color }) => (
    <div className="changeLanguage__container" style={{
        top: top,
        color: color,
        padding: ".5rem",
    }} onClick={onClick}>
        {text}
    </div>
)

export default FloatingButtonContainer;