const FIRST_PARAGRAPH = {
    en: "The order management system was developed for the order management department for Foxconn at Ciudad Juarez, the main goal of this system was process all the data that the fabric had in excel files.",
    es: "El sistema de gestión de pedidos fue desarrollado para el departamento de gestión de pedidos de Foxconn en Ciudad Juarez, el objetivo principal de este sistema fue procesar todos los datos que la fábrica tenía en archivos de excel."
}

const SECOND_PARAGRAPH = {
    en: "Most of the work consisted on automatize all the processes that the department was doing at that time. Some of them were difficult to implement. With this, the department was be able to do more work without any human intervention.",
    es: "La mayoría del trabajo consistió en automatizar todos los procesos que el departamento estaba haciendo en ese momento. Algunos de estos fueron complicados en su implementacion. Con esto el departamento fue capaz de hacer mas trabajo sin ninguna intervencion humana."
}

const THIRD_PARAGRAPH = {
    en: "Some processes were automatized:",
    es: "Algunos procesos que fueron automatizados:"
}

const FIRST_ITEM_AUTOMATIZED_LIST = {
    en: "Automatization of the upload of invoices.",
    es: "Automatizacion de subida de facturas."
}

const SECOND_ITEM_AUTOMATIZED_LIST = {
    en: "Automatization of the search of missing materials in the warehouse for production.",
    es: "Automatizacion de busqueda de materiales faltantes en el almacen para la produccion."
}

const THIRD_ITEM_AUTOMATIZED_LIST = {
    en: "Automatization of the reports for customers.",
    es: "Automatizacion de los reportes para los clientes."
}

export {
    FIRST_PARAGRAPH,
    SECOND_PARAGRAPH,
    THIRD_PARAGRAPH,
    FIRST_ITEM_AUTOMATIZED_LIST,
    SECOND_ITEM_AUTOMATIZED_LIST,
    THIRD_ITEM_AUTOMATIZED_LIST
}