import React from "react";
import "./Carrousel.css";

const Carrousel = ({ imgs }) => {
    const onClick = (e) => {
        window.open(e.target.src, "_blank");
    }

    const CreateImgsTags =
        imgs.map(img => {
            return (
                <img src={img} alt={img} className="carrousel-img" id={img} key={img} onClick={e => onClick(e)}/>
            )
        })

    return (
        <div>
            <center>
                <div className="carrousel-border">
                    {CreateImgsTags}
                </div>
            </center>

        </div >
    )
}

export default Carrousel;