import {
    VUEJS,
    REACT,
    REDUX,
    LARAVEL,
    ELECTRON,
    SPA,
    PHP_OFFICE,
    BOTMAN_PHP,
    GOOGLE_MAPS,
    MYSQL,
    POSTGRESQL,
    VANILLA_JAVASCRIPT,
    TYPESCRIPT,
    JQUERY,
    NODEJS,
    EXPRESS,
    KOA,
    MONGODB,
    MONGOOSE,
    RABBITMQ,
    ITEXT,
    JWT,
    XTERMJS,
    SOCKET_IO,
    BASH,
    VANILLA_PHP,
    PHALCON,
    DOCKER,
    KUBERNETES,
    PYTHON,
    GOLANG,
    JAVA,
    PANDAS,
    NUMPY,
    SELENIUM,
    PY_WIN_32,
    SAP_SCRIPT_RECORDER,
    AWS,
} from './strings';

import navigatorLanguage from '../../utilities/language';
const lang = navigatorLanguage();

const langsAndTechsStrings = () => ({
    VUEJS: VUEJS[lang],
    REACT: REACT[lang],
    REDUX: REDUX[lang],
    LARAVEL: LARAVEL[lang],
    ELECTRON: ELECTRON[lang],
    SPA: SPA[lang],
    PHP_OFFICE: PHP_OFFICE[lang],
    BOTMAN_PHP: BOTMAN_PHP[lang],
    GOOGLE_MAPS: GOOGLE_MAPS[lang],
    MYSQL: MYSQL[lang],
    POSTGRESQL: POSTGRESQL[lang],
    VANILLA_JAVASCRIPT: VANILLA_JAVASCRIPT[lang],
    TYPESCRIPT: TYPESCRIPT[lang],
    JQUERY: JQUERY[lang],
    NODEJS: NODEJS[lang],
    EXPRESS: EXPRESS[lang],
    KOA: KOA[lang],
    MONGODB: MONGODB[lang],
    MONGOOSE: MONGOOSE[lang],
    RABBITMQ: RABBITMQ[lang],
    ITEXT: ITEXT[lang],
    JWT: JWT[lang],
    XTERMJS: XTERMJS[lang],
    SOCKET_IO: SOCKET_IO[lang],
    BASH: BASH[lang],
    VANILLA_PHP: VANILLA_PHP[lang],
    PHALCON: PHALCON[lang],
    DOCKER: DOCKER[lang],
    KUBERNETES: KUBERNETES[lang],
    PYTHON: PYTHON[lang],
    GOLANG: GOLANG[lang],
    JAVA: JAVA[lang],
    PANDAS: PANDAS[lang],
    NUMPY: NUMPY[lang],
    SELENIUM: SELENIUM[lang],
    PY_WIN_32: PY_WIN_32[lang],
    SAP_SCRIPT_RECORDER: SAP_SCRIPT_RECORDER[lang],
    AWS: AWS[lang],
});

export default langsAndTechsStrings;