const VUEJS_LINK = { link: 'https://vuejs.org/' };
const VUEJS = {
    en: {
        text: "Vue.js",
        ...VUEJS_LINK,
    },
    es: {
        text: "Vue.js",
        ...VUEJS_LINK,
    }
}

const REACT_LINK = { link: 'https://reactjs.org/' };

const REACT = {
    en: {
        text: "React",
        ...REACT_LINK,
    },
    es: {
        text: "React",
        ...REACT_LINK,
    }
}

const REDUX_LINK = { link: 'https://redux.js.org/' };

const REDUX = {
    en: {
        text: "Redux",
        ...REDUX_LINK,
    },
    es: {
        text: "Redux",
        ...REDUX_LINK,
    }
}

const ELECTRON_LINK = { link: 'https://www.electronjs.org/' };

const ELECTRON = {
    en: {
        text: "Electron",
        ...ELECTRON_LINK,
    },
    es: {
        text: "Electron",
        ...ELECTRON_LINK,
    }
}

const EXPRESS_LINK = { link: 'https://expressjs.com/' };

const EXPRESS = {
    en: {
        text: "Express",
        ...EXPRESS_LINK,
    },
    es: {
        text: "Express",
        ...EXPRESS_LINK,
    }
}

const KOA_LINK = { link: 'https://koajs.com/' };

const KOA = {
    en: {
        text: "Koa",
        ...KOA_LINK,
    },
    es: {
        text: "Koa",
        ...KOA_LINK,
    }
}

const LARAVEL_LINK = { link: 'https://laravel.com/' };

const LARAVEL = {
    en: {
        text: "Laravel",
        ...LARAVEL_LINK,
    },
    es: {
        text: "Laravel",
        ...LARAVEL_LINK,
    }
}

const SPA_LINK = { link: 'https://en.wikipedia.org/wiki/Single-page_application' };

const SPA = {
    en: {
        text: "Single Page Application",
        ...SPA_LINK,
    },
    es: {
        text: "Aplicación de una sola página",
        ...SPA_LINK,
    }
}

const PHP_OFFICE_LINK = { link: 'https://php.net/' };

const PHP_OFFICE = {
    en: {
        text: "PHP Office",
        ...PHP_OFFICE_LINK,
    },
    es: {
        text: "PHP Office",
        ...PHP_OFFICE_LINK,
    }
}

const BOTMAN_PHP_LINK = { link: 'https://botman.io/' };

const BOTMAN_PHP = {
    en: {
        text: "Botman PHP",
        ...BOTMAN_PHP_LINK,
    },
    es: {
        text: "Botman PHP",
        ...BOTMAN_PHP_LINK,
    }
}

const GOOGLE_MAPS_LINK = { link: 'https://developers.google.com/maps/documentation/javascript/tutorial' };

const GOOGLE_MAPS = {
    en: {
        text: "Google Maps",
        ...GOOGLE_MAPS_LINK,
    },
    es: {
        text: "Google Maps",
        ...GOOGLE_MAPS_LINK,
    }
}

const MYSQL_LINK = { link: 'https://www.mysql.com/' };

const MYSQL = {
    en: {
        text: "MySQL",
        ...MYSQL_LINK,
    },
    es: {
        text: "MySQL",
        ...MYSQL_LINK,
    }
}

const POSTGRESQL_LINK = { link: 'https://www.postgresql.org/' };

const POSTGRESQL = {
    en: {
        text: "PostgreSQL",
        ...POSTGRESQL_LINK,
    },
    es: {
        text: "PostgreSQL",
        ...POSTGRESQL_LINK,
    }
}

const MONGODB_LINK = { link: 'https://www.mongodb.com/' };

const MONGODB = {
    en: {
        text: "MongoDB",
        ...MONGODB_LINK,
    },
    es: {
        text: "MongoDB",
        ...MONGODB_LINK,
    }
}

const MONGOOSE_LINK = { link: 'https://mongoosejs.com/' };

const MONGOOSE = {
    en: {
        text: "Mongoose",
        ...MONGOOSE_LINK,
    },
    es: {
        text: "Mongoose",
        ...MONGOOSE_LINK,
    }
}

const RABBITMQ_LINK = { link: 'https://www.rabbitmq.com/' };

const RABBITMQ = {
    en: {
        text: "RabbitMQ",
        ...RABBITMQ_LINK,
    },
    es: {
        text: "RabbitMQ",
        ...RABBITMQ_LINK,
    }
}

const ITEXT_LINK = { link: 'https://itextpdf.com/en' };

const ITEXT = {
    en: {
        text: "iText",
        ...ITEXT_LINK,
    },
    es: {
        text: "iText",
        ...ITEXT_LINK,
    }
}

const JAVASCRIPT_LINK = { link: 'https://developer.mozilla.org/en-US/docs/Web/JavaScript' };

const VANILLA_JAVASCRIPT = {
    en: {
        text: "Vanilla JavaScript",
        ...JAVASCRIPT_LINK,
    },
    es: {
        text: "Vanilla JavaScript",
        ...JAVASCRIPT_LINK,
    }
}

const TYPESCRIPT_LINK = { link: 'https://www.typescriptlang.org/' };

const TYPESCRIPT = {
    en: {
        text: "TypeScript",
        ...TYPESCRIPT_LINK,
    },
    es: {
        text: "TypeScript",
        ...TYPESCRIPT_LINK,
    }
}

const JQUERY_LINK = { link: 'https://jquery.com/' };

const JQUERY = {
    en: {
        text: "jQuery",
        ...JQUERY_LINK,
    },
    es: {
        text: "jQuery",
        ...JQUERY_LINK,
    }
}

const NODEJS_LINK = { link: 'https://nodejs.org/' };

const NODEJS = {
    en: {
        text: "NodeJS",
        ...NODEJS_LINK,
    },
    es: {
        text: "NodeJS",
        ...NODEJS_LINK,
    }
}

const JWT_LINK = { link: 'https://jwt.io/' };

const JWT = {
    en: {
        text: "JWT",
        ...JWT_LINK,
    },
    es: {
        text: "JWT",
        ...JWT_LINK,
    }
}

const XTERMJS_LINK = { link: 'https://xtermjs.org/' };

const XTERMJS = {
    en: {
        text: "xterm.js",
        ...XTERMJS_LINK,
    },
    es: {
        text: "xterm.js",
        ...XTERMJS_LINK,
    }
}

const SOCKET_IO_LINK = { link: 'https://socket.io/' };

const SOCKET_IO = {
    en: {
        text: "Socket.io",
        ...SOCKET_IO_LINK,
    },
    es: {
        text: "Socket.io",
        ...SOCKET_IO_LINK,
    }
}

const BASH_LINK = { link: 'https://www.gnu.org/software/bash/' };

const BASH = {
    en: {
        text: "Bash",
        ...BASH_LINK,
    },
    es: {
        text: "Bash",
        ...BASH_LINK,
    }
}

const VANILLA_PHP_LINK = { link: 'https://www.php.net/' };

const VANILLA_PHP = {
    en: {
        text: "Vanilla PHP",
        ...VANILLA_PHP_LINK,
    },
    es: {
        text: "Vanilla PHP",
        ...VANILLA_PHP_LINK,
    }
}

const PHALCON_LINK = { link: 'https://phalcon.io/' };

const PHALCON = {
    en: {
        text: "Phalcon",
        ...PHALCON_LINK,
    },
    es: {
        text: "Phalcon",
        ...PHALCON_LINK,
    }
}

const DOCKER_LINK = { link: 'https://www.docker.com/' };

const DOCKER = {
    en: {
        text: "Docker",
        ...DOCKER_LINK,
    },
    es: {
        text: "Docker",
        ...DOCKER_LINK,
    }
}

const KUBERNETES_LINK = { link: 'https://kubernetes.io/' };

const KUBERNETES = {
    en: {
        text: "Kubernetes",
        ...KUBERNETES_LINK,
    },
    es: {
        text: "Kubernetes",
        ...KUBERNETES_LINK,
    }
}

const PYTHON_LINK = { link: 'https://www.python.org/' };

const PYTHON = {
    en: {
        text: "Python",
        ...PYTHON_LINK,
    },
    es: {
        text: "Python",
        ...PYTHON_LINK,
    }
}

const GOLANG_LINK = { link: 'https://golang.org/' };

const GOLANG = {
    en: {
        text: "Golang",
        ...GOLANG_LINK,
    },
    es: {
        text: "Golang",
        ...GOLANG_LINK,
    }
}

const JAVA_LINK = { link: 'https://www.java.com/' };

const JAVA = {
    en: {
        text: "Java",
        ...JAVA_LINK,
    },
    es: {
        text: "Java",
        ...JAVA_LINK,
    }
}

const PANDAS_LINK = { link: 'https://pandas.pydata.org/' };

const PANDAS = {
    en: {
        text: "Pandas",
        ...PANDAS_LINK,
    },
    es: {
        text: "Pandas",
        ...PANDAS_LINK,
    }
}

const NUMPY_LINK = { link: 'https://numpy.org/' };

const NUMPY = {
    en: {
        text: "Numpy",
        ...NUMPY_LINK,
    },
    es: {
        text: "Numpy",
        ...NUMPY_LINK,
    }
}

const SELENIUM_LINK = { link: 'https://www.selenium.dev/' };

const SELENIUM = {
    en: {
        text: "Selenium",
        ...SELENIUM_LINK,
    },
    es: {
        text: "Selenium",
        ...SELENIUM_LINK,
    }
}

const PY_WIN_32_LINK = { link: 'https://pypi.org/project/pywin32/' };

const PY_WIN_32 = {
    en: {
        text: "PyWin32",
        ...PY_WIN_32_LINK,
    },
    es: {
        text: "PyWin32",
        ...PY_WIN_32_LINK,
    }
}

const SAP_SCRIPT_RECORDER_LINK = { link: 'https://blogs.sap.com/2014/08/04/script-recording-playback-for-dummies/' };

const SAP_SCRIPT_RECORDER = {
    en: {
        text: "SAP Script Recorder",
        ...SAP_SCRIPT_RECORDER_LINK,
    },
    es: {
        text: "SAP Script Recorder",
        ...SAP_SCRIPT_RECORDER_LINK,
    }
}

const AWS_LINK = { link: 'https://aws.amazon.com/' };

const AWS = {
    en: {
        text: "AWS",
        ...AWS_LINK,
    },
    es: {
        text: "AWS",
        ...AWS_LINK,
    }
}

export {
    VUEJS,
    REACT,
    REDUX,
    ELECTRON,
    LARAVEL,
    SPA,
    PHP_OFFICE,
    BOTMAN_PHP,
    GOOGLE_MAPS,
    MYSQL,
    POSTGRESQL,
    VANILLA_JAVASCRIPT,
    TYPESCRIPT,
    JQUERY,
    NODEJS,
    EXPRESS,
    KOA,
    MONGODB,
    MONGOOSE,
    RABBITMQ,
    ITEXT,
    JWT,
    XTERMJS,
    SOCKET_IO,
    BASH,
    VANILLA_PHP,
    PHALCON,
    DOCKER,
    KUBERNETES,
    PYTHON,
    GOLANG,
    JAVA,
    PANDAS,
    NUMPY,
    SELENIUM,
    PY_WIN_32,
    SAP_SCRIPT_RECORDER,
    AWS,
}