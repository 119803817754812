import navigatorLanguage from "../../utilities/language";
export const changeLanguage = (language) => {
    localStorage.setItem("language", language);
    window.location.reload();
}

const colorNotSelected = "rgb(188 177 180)";
const colorSelected = "rgb(255, 253, 254)";

const language = navigatorLanguage();
export const colorES = language === "es" ? colorSelected : colorNotSelected;
export const colorEN = language === "en" ? colorSelected : colorNotSelected;
export const topES = (top) => top ? `${Number(top)}px` : "10px";
export const topEN = (top) => top ? `${Number(top + 40)}px` : "50px";