import BlockLink from "../blockLink";
const PortfolioLinks = () => (
    <div>
        <BlockLink text="Remine" link="#remine_portfolio" />
        <BlockLink text="CH Finance App" link="#chFinance_portfolio" />
        <BlockLink text="IUSPak" link="#iuspak_portfolio" />
        <BlockLink text="Nautilus" link="#nautilus_portfolio" />
        <BlockLink text="Microsoft Testing App" link="#microsoft_portfolio" />
        <BlockLink text="OMS (Order Management System)" link="#oms_portfolio" />
    </div>
)

export default PortfolioLinks;