const FIRST_PARAGRAPH = {
    en: "This was a project I worked for Remine, is a system for real state agents so they can handle all their transactions, documents, deals, etc.",
    es: "Este fue un proyecto en el que trabajé para la empresa Remine, es un sistema para agentes inmobiliarios para que puedan manejar todas sus transacciones, documentos, tratos, etc.",
}

const SECOND_PARAGRAPH = {
    en: "It allows agents to upload documents, sign them, share the documents with their clients and handle all kind of actions with their documents.",
    es: "Permite a los agentes subir documentos, firmarlos, compartir los documentos con sus clientes y manejar todo tipo de acciones con sus documentos.",
}

const THIRD_PARAGRAPH = {
    en: "Mainly I worked in this product, but there was another services that we used and that we needed to mantain or make changes to it.",
    es: "Principalmente trabajé en este producto, pero había otros servicios que usábamos y que necesitábamos mantener o hacer cambios en ellos.",
}

const FOURTH_PARAGRAPH = {
    en: "All services run on docker and are orchested with kubernetes.",
    es: "Todos los servicios se ejecutan en docker y están orquestados con kubernetes.",
}

export {
    FIRST_PARAGRAPH,
    SECOND_PARAGRAPH,
    THIRD_PARAGRAPH,
    FOURTH_PARAGRAPH,
}