import Carrousel from '../../../components/carrousel';
import strings from '../../../strings/portfolio';
import RectanglesList from '../../../components/rectanglesList';
import langsAndTechsStrings from '../../../strings/langsAndTechs';
import { COMPLEXITIES } from '../../../strings/complexities';
import paragraphs from '../../../strings/portfolio/iuspak';
import IusPakImgs from "./portfolio_imgs";
import Header from '../../../components/header';

const texts = strings();
const iuspakParagraphs = paragraphs();
const langsAndTechs = langsAndTechsStrings();

const DEVELOPED_IN = [
    langsAndTechs.VUEJS,
    langsAndTechs.LARAVEL,
    langsAndTechs.SPA,
    langsAndTechs.PHP_OFFICE,
    langsAndTechs.BOTMAN_PHP,
    langsAndTechs.GOOGLE_MAPS,
    langsAndTechs.MYSQL,
];

const Main = () => (
    <div className="main__portfolio longText">
        <Header text={texts.IUSPAK} level={2} id="iuspak_portfolio" />
        <div>
            <Header text={texts.DEVELOPED_IN} level={3} />
            <RectanglesList listItems={DEVELOPED_IN} />
        </div>
        <br></br>
        <Header text={texts.COMPLEXITY} level={3} />
        <RectanglesList listItems={[{ text: COMPLEXITIES.MEDIUM, link: "#" }]} />
        <Carrousel imgs={IusPakImgs} carrouselName="IusPak" />
        <p className="longText">{iuspakParagraphs.FIRST_PARAGRAPH}</p>
        <p className="longText">{iuspakParagraphs.SECOND_PARAGRAPH}</p>
        <p className="longText">{iuspakParagraphs.THIRD_PARAGRAPH}</p>
        <p className="longText">{iuspakParagraphs.FOURTH_PARAGRAPH}</p>
        <p className="longText">{iuspakParagraphs.FIFTH_PARAGRAPH}</p>
        <p className="longText">{iuspakParagraphs.SIXTH_PARAGRAPH}</p>
        <Header text={iuspakParagraphs.IUSPAK_MOBILE_TITLE} level={3} />
        <p>{iuspakParagraphs.IUSPAK_MOBILE_FIRST_PARAGRAPH1}</p>
    </div>
);

export default Main;