import strings from '../../../strings/portfolio';
import langsAndTechsStrings from '../../../strings/langsAndTechs';
import { COMPLEXITIES } from '../../../strings/complexities';
import RectanglesList from '../../../components/rectanglesList';
import paragraphs from '../../../strings/portfolio/microsoft';
import Header from '../../../components/header';

const texts = strings();
const langsAndTechs = langsAndTechsStrings();
const microsoftParagraphs = paragraphs();

const DEVELOPED_IN = [
    langsAndTechs.REACT,
    langsAndTechs.LARAVEL,
    langsAndTechs.SPA,
    langsAndTechs.NODEJS,
    langsAndTechs.XTERMJS,
    langsAndTechs.SOCKET_IO,
    langsAndTechs.BASH,
    langsAndTechs.MYSQL,
];

const Main = () => (
    <div className="main__portfolio longText">
        <Header text={texts.MICROSOFT} level={2} id="microsoft_portfolio" />
        <div>
            <Header text={texts.DEVELOPED_IN} level={3} />
            <RectanglesList listItems={DEVELOPED_IN} />
        </div>
        <Header text={texts.COMPLEXITY} level={3} />
        <RectanglesList listItems={[{ text: COMPLEXITIES.HIGH, link: "#" }]} />
        <p className="longText">{microsoftParagraphs.FIRST_PARAGRAPH}</p>
        <p className="longText">{microsoftParagraphs.SECOND_PARAGRAPH}</p>
        <Header text={texts.UUT_PROCESS} level={3} />
        <p className="longText">{microsoftParagraphs.THIRD_PARAGRAPH}</p>
        <p className="longText">{microsoftParagraphs.FOURTH_PARAGRAPH}</p>
        <Header text={texts.USER_PROCESS} level={3} />
        <p className="longText">{microsoftParagraphs.FIFTH_PARAGRAPH}</p>
    </div>
);

export default Main;