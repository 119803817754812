import './App.css';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import BioInfoWithLinks from "./pages/bio";
import Maintenance from "./pages/maintenance";
import Portfolio from "./pages/portfolio";

const MaintenanceMode = false;

const App = () => (
  <Router>
    <div>
      <Switch>
        <Route exact path="/">
          {MaintenanceMode && <Maintenance />}
          {!MaintenanceMode && <BioInfoWithLinks />}
        </Route>
        <Route path="/portfolio">
          <Portfolio />
        </Route>
      </Switch>
    </div>
  </Router>
);

export default App;
