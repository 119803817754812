const WARNING_ABOUT_SITE = {
    en: "This site is oriented to technical users with who share knowledge and information (other developers, IT professionals and/or entusiasts), if you are searching for a developer or you are a recruiter please visit: ",
    es: "Este sitio esta destinado a usuarios tecnicos con los que compartir conocimientos y/o informacion (otros programadores o profesionales de TI y/o entusiastas), si estas buscando un programador o eres un reclutador por favor visita: "
};

const NEVER_SHOW_AGAIN = {
    en: "Never show again",
    es: "No mostrar de nuevo",
};

const CLOSE_BUTTON = {
    en: "Close",
    es: "Cerrar"
};


export { WARNING_ABOUT_SITE, NEVER_SHOW_AGAIN, CLOSE_BUTTON };