import FakePropsComponent from "../fakePropsComponent";
import strings from "../../strings/bio/index";
const texts = strings();

const AboutMe = () => (
    <div className="main" style={{
        textAlign: "left"
    }}>
        <p style={{ fontSize: "1.5rem", textAlign: "center" }}>{texts.BIO_GREETING}</p>
        <FakePropsComponent />
    </div>
);

export default AboutMe;