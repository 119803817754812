import {
    FIRST_PARAGRAPH,
    SECOND_PARAGRAPH,
    THIRD_PARAGRAPH,
    MODULES_LIST,
    FOURTH_PARAGRAPH,
} from './strings';

import navigatorLanguage from '../../../utilities/language';
const lang = navigatorLanguage();

const strings = () => (
    {
        FIRST_PARAGRAPH: FIRST_PARAGRAPH[lang],
        SECOND_PARAGRAPH: SECOND_PARAGRAPH[lang],
        THIRD_PARAGRAPH: THIRD_PARAGRAPH[lang],
        MODULES_LIST: MODULES_LIST[lang],
        FOURTH_PARAGRAPH: FOURTH_PARAGRAPH[lang],
    });

export default strings;