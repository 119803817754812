const noLink = (link) => link === "#";
const linkToUse = (link) => noLink(link) ? "" : link;
const selfOrBlank = (link) => noLink(link) ? "_self" : "_blank";

const ContentItem = ({ item, index }) => {
    if (noLink(item.link)) {
        return (
            <li className="inlineListElement"><div className="inlineListElementContainer" style={{
                color: "#feffce",
            }}>{item.text}</div></li>
        )
    }

    return (
        <li className="inlineListElement" key={index}>
            <div className="inlineListElementContainer">
            <a href={linkToUse(item.link)} target={selfOrBlank(item.link)} rel="noreferrer" style={{
                color: "#feffce",
            }}>
                {item.text}
            </a>
            </div>
            
        </li>
    )
};

const RectanglesList = ({ listItems }) => (
    <ul className="list">
        {listItems.map((item, index) => {
            return (
                <ContentItem item={item} index={index} key={index} />
            )
        })}
    </ul>
);

export default RectanglesList;