import SpanColor from "../spanColor";
import { FakeProps } from "./strings";

const FakePropsComponent = () => (
    <>
        <SpanColor color="rgb(103 102 94)" spanContent="const " />
        <SpanColor color="rgb(103 102 94)" spanContent="Props " />
        <SpanColor color="rgb(178 153 129)" spanContent="= " />
        <SpanColor color="rgb(178 153 129)" spanContent="{" />
        {Object.keys(FakeProps).map((key) => (
            <div key={key}>
                <SpanColor color="rgb(132 121 101)" spanContent={key} additionalStyles={{
                    marginLeft: "1rem",
                }} />
                <SpanColor color="rgb(103 102 94)" spanContent=": " />
                <SpanColor color="rgb(75 72 64)" spanContent={FakeProps[key]} />
            </div>
        ))}
        <SpanColor color="rgb(178 153 129)" spanContent="}" />
    </>
);

export default FakePropsComponent;