import Sales from '../../../assets/imgs/chfinance/sales.png';
import Collaborators from '../../../assets/imgs/chfinance/collaborators.png';
import Dashboard from '../../../assets/imgs/chfinance/dashboard.png';
import Reports from '../../../assets/imgs/chfinance/reports.png';
import Finances from '../../../assets/imgs/chfinance/finances.png';

const ChFinanceImgs = [
        // Need fix
        // Login,
        Sales,
        Collaborators,
        Dashboard,
        Reports,
        Finances
]

export default ChFinanceImgs;