import Carrousel from "../../../components/carrousel";
import strings from '../../../strings/portfolio';
import RectanglesList from "../../../components/rectanglesList";
import Header from "../../../components/header";
import paragraphs from '../../../strings/portfolio/oms';
import langsAndTechsStrings from '../../../strings/langsAndTechs';
import { COMPLEXITIES } from '../../../strings/complexities';
import OMSImgs from "./portfolio_imgs";

const texts = strings();
const omsParagraphs = paragraphs();
const langsAndTechs = langsAndTechsStrings();

const DEVELOPED_IN = [
    langsAndTechs.PYTHON,
    langsAndTechs.PANDAS,
    langsAndTechs.NUMPY,
    langsAndTechs.ELECTRON,
    langsAndTechs.NODEJS,
    langsAndTechs.SELENIUM,
    langsAndTechs.PY_WIN_32,
    langsAndTechs.SAP_SCRIPT_RECORDER,
];

const OMS = () => (
    <div className="main__portfolio longText">
        <Header text={texts.OMS} level={2} id="oms_portfolio" />
        <Header text={texts.DEVELOPED_IN} level={3} />
        <RectanglesList listItems={DEVELOPED_IN} />
        <Header text={texts.COMPLEXITY} level={3} />
        <RectanglesList listItems={[{ text: COMPLEXITIES.MEDIUM, link: "#" }]} />
        <Carrousel imgs={OMSImgs} carrouselName={texts.OMS} />
        <p className="longText">{omsParagraphs.FIRST_PARAGRAPH}</p>
        <p className="longText">
            {omsParagraphs.SECOND_PARAGRAPH}
        </p>
        <p>{omsParagraphs.THIRD_PARAGRAPH}</p>
        <ul>
            <li>{omsParagraphs.FIRST_ITEM_AUTOMATIZED_LIST}</li>
            <li>{omsParagraphs.SECOND_ITEM_AUTOMATIZED_LIST}</li>
            <li>{omsParagraphs.THIRD_ITEM_AUTOMATIZED_LIST}</li>
        </ul>
    </div>
);

export default OMS;