import Comments from "../../../assets/imgs/nautilus/comentarios.jpg";
import Status from "../../../assets/imgs/nautilus/estatus.jpg";
import Index from "../../../assets/imgs/nautilus/index.jpg";
import Terminal from "../../../assets/imgs/nautilus/terminal.jpg";
import Wo from "../../../assets/imgs/nautilus/wo.jpg";

const NautilusImgs = [
    Terminal,
    Comments,
    Status,
    Index,
    Wo
];

export default NautilusImgs;