import BuscarClientes from "../../../assets/imgs/iuspak/buscar_clientes.jpg";
import ChatBot from "../../../assets/imgs/iuspak/chatBot.png";
import ErrorControl from "../../../assets/imgs/iuspak/error_control.png";
import NewCotization from "../../../assets/imgs/iuspak/nueva_cotizacion.png";
import NewContract from "../../../assets/imgs/iuspak/perfil_cliente.jpg";

const IusPakImgs = [
        BuscarClientes,
        ChatBot,
        ErrorControl,
        NewCotization,
        NewContract,
];

export default IusPakImgs;