import strings from "../../strings/bio/index";

const texts = strings();
const MainLinks = () => (
    <div className="links__bar">
        <a
            className="App-link"
            href="/portfolio"
            rel="noopener noreferrer"
        >
            {texts.PORTFOLIO}
        </a>
        <a
            className="App-link"
            href="https://github.com/IvanFuentesR"
            target="_blank"
            rel="noopener noreferrer"
        >
            {texts.GITHUB}
        </a>
        <a
            className="App-link"
            href="https://www.linkedin.com/in/ivan-fuentes-2513b6158/"
            target="_blank"
            rel="noopener noreferrer"
        >
            {texts.LINKEDIN}
        </a>
        <a
            className="App-link"
            href="mailto:ivan.fuentes.r@outlook.com"
            rel="noopener noreferrer"
        >
            {texts.CONTACT}
        </a>
    </div>
)

export default MainLinks;