const FIRST_PARAGRAPH = {
    en: "This was a project that I worked for the business CH Finance, which it's a business that connects investors with project that need funds.",
    es: "Este fue un proyecto en el que trabajé para la empresa CH Finance, la cual es una empresa que conecta inversores con proyectos que necesitan fondos.",
}

const SECOND_PARAGRAPH = {
    en: "CH Finance needed an enterprise resource planning system that allowed them to manage all the resources of the business.",
    es: "CH Finance necesitaba un sistema de planificación de recursos empresariales que les permitiera administrar todos los recursos de la empresa.",
}

const THIRD_PARAGRAPH = {
    en: "This system has the next modules:",
    es: "Este sistema tiene los siguientes módulos:",
}

const MODULES_LIST = {
    en: [
        "Dashboard",
        "Sales",
        "Collaborators",
        "Finances",
        "Reports",
    ],
    es: [
        "Dashboard",
        "Ventas",
        "Colaboradores",
        "Finanzas",
        "Reportes",
    ],
}

const FOURTH_PARAGRAPH = {
    en: "And every one of this modules has submodules inside of it.",
    es: "Y cada uno de estos módulos tiene submódulos dentro de él.",
}

export {
    FIRST_PARAGRAPH,
    SECOND_PARAGRAPH,
    THIRD_PARAGRAPH,
    MODULES_LIST,
    FOURTH_PARAGRAPH,
}