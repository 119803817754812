
import LinkButton from "../../common/LinkButton";
import IUSPak from "./iuspak";
import Nautilus from "./nautilus";
import MicrosoftTestingApp from "./microsoft";
import OMS from "./oms";
import ChFinance from "./chFinance";
import Remine from "./remine";
import MainText from "./mainText";
import ChangeLanguage from "../../components/changeLanguage";

const Main = () => (
    <>
        <LinkButton textButton="&#8592;" url="/" />
        <ChangeLanguage top={60} />
        <MainText />
        <Remine />
        <ChFinance />
        <IUSPak />
        <Nautilus />
        <MicrosoftTestingApp />
        <OMS />
    </>
);

export default Main;