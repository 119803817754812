import {
    FIRST_PARAGRAPH,
    SECOND_PARAGRAPH,
    THIRD_PARAGRAPH,
    FIRST_ITEM_AUTOMATIZED_LIST,
    SECOND_ITEM_AUTOMATIZED_LIST,
    THIRD_ITEM_AUTOMATIZED_LIST
} from './strings';

import navigatorLanguage from '../../../utilities/language';
const lang = navigatorLanguage();

const strings = () => ({
    FIRST_PARAGRAPH: FIRST_PARAGRAPH[lang],
    SECOND_PARAGRAPH: SECOND_PARAGRAPH[lang],
    THIRD_PARAGRAPH: THIRD_PARAGRAPH[lang],
    FIRST_ITEM_AUTOMATIZED_LIST: FIRST_ITEM_AUTOMATIZED_LIST[lang],
    SECOND_ITEM_AUTOMATIZED_LIST: SECOND_ITEM_AUTOMATIZED_LIST[lang],
    THIRD_ITEM_AUTOMATIZED_LIST: THIRD_ITEM_AUTOMATIZED_LIST[lang],
});

export default strings;