const DEVELOPED_IN = {
    en: 'Developed in',
    es: 'Desarrollado en'
}

const COMPLEXITY = {
    en: 'Complexity',
    es: 'Complejidad'
}

const UUT_PROCESS = {
    en: 'UUT process (Unit under test, server)',
    es: 'Proceso UUT (Unidad de prueba, servidor)'
}

const USER_PROCESS = {
    en: 'User process',
    es: 'Proceso de usuario'
}

const NAUTILUS = {
    en: "Nautilus",
    es: "Nautilus",
}

const CHFINANCE = {
    en: "CH Finance",
    es: "CH Finance",
}

const IUSPAK = {
    en: "IusPak",
    es: "IusPak",
}

const MICROSOFT = {
    en: "Microsoft Testing App",
    es: "App de pruebas Microsoft",
}

const OMS = {
    en: "OMS (Order Management System)",
    es: "OMS (Sistema de gestión de pedidos)",
}

const REMINE = {
    en: "Remine",
    es: "Remine",
}

const FIRST_PARAGRAPH = {
    en: "I had work in several projects through the years. Some of them (and the most relevants to me) are:",
    es: "He trabajado en varios proyectos a través de los años. Algunos de ellos (y los más relevantes para mí) son:",
}

const SECOND_PARAGRAPH = {
    en: "You can click any of them to see more details below about the clicked project.",
    es: "Puedes hacer click en cualquiera de ellos para ver más detalles sobre el proyecto seleccionado.",
}

const THIRD_PARAGRAPH = {
    en: "All these projects were carried or are running in a production environment.",
    es: "Todos estos proyectos fueron llevados o funcionaban en un entorno de producción.",
}

export {
    DEVELOPED_IN,
    COMPLEXITY,
    UUT_PROCESS,
    USER_PROCESS,
    NAUTILUS,
    CHFINANCE,
    IUSPAK,
    MICROSOFT,
    OMS,
    REMINE,
    FIRST_PARAGRAPH,
    SECOND_PARAGRAPH,
    THIRD_PARAGRAPH,
};