import MainStack from "./mainStack";
import WorkedWith from "./workedWith";
import strings from "../../strings/bio/index";

const texts = strings();

const Stack = () => (
    <>
        <p style={{ textAlign: "left" }}>{texts.MAIN_STACK}</p>
        <MainStack />
        <p style={{ textAlign: "left" }}>{texts.WORKED_WITH}</p>
        <WorkedWith />
    </>
);

export default Stack;