const FIRST_PARAGRAPH = {
    en: "This was an extension of the Nautilus app. This project was made for the same fabric that assemblies servers, specifically for a business unit where they sell servers to Microsoft",
    es: "Esta es una extensión de la aplicación Nautilus. Este proyecto fue hecho para la misma fábrica que monta servidores, especificamente para una unidad de negocio donde se venden servidores a Microsoft",
}

const SECOND_PARAGRAPH = {
    en: "The main objective of this system was connect several servers that were under testing (The fabric assemblies the servers, then enters to the testing area where the servers runs several tests, stress tests, FIO tests, functional tests, etc) to obtain real time information about the servers, what they are running, how many, how many are failing, etc.",
    es: "El objetivo principal de este sistema fue conectar varios servidores que estaban bajo pruebas (La fábrica monta los servidores, luego entra a la zona de pruebas donde los servidores ejecutan varios tests, tests de estrés, tests FIO, tests funcionales, etc.) para obtener información real de los servidores, qué están ejecutando, cuántos hay en prueba, cuántos están fallando, etc.",
}

const THIRD_PARAGRAPH ={ 
    en: "All the servers can be manipulated through web terminals (uppon 2 thousand terminals). Basically when a sever (UUT since here, Unit Under Test) boots it runs a bash script (Via bash_profile) that loads a NodeJS app, this NodeJS runs a Pty (pseudo terminal) and open a socket to connect to the main server. Through the Pty and the socket the UUT sends all the output and input of the Pty.",
    es: "Todos los servidores pueden ser manipulados a través de terminales web (hasta 2.000 terminales). Básicamente cuando un servidor (UUT, Unit Under Test) arranca ejecuta un script bash (a través del bash_profile) que carga una aplicación NodeJS, esta aplicación NodeJS ejecuta un Pty (pseudo terminal) y abre un socket para conectarse al servidor principal. A través del Pty y del socket el UUT envía todo el output y input del Pty.",
}

const FOURTH_PARAGRAPH = {
    en: "After this the UUT sends its information, like serial number, asset tag, mainboard serial number, ip, mac address, etc.",
    es: "Después de esto el UUT envía su información, como número de serie, etiqueta de activo, número de serie del mainboard, ip, dirección mac, etc.",
}

const FIFTH_PARAGRAPH = {
    en: "When a user enters to the web app, it could connect to whatever server he wants, then a web terminal is drawed using Xterm JS, this xterm is connected to the real UUT using the socket that is identified by the serial number of the mainboard.",
    es: "Cuando un usuario entra a la aplicación web, puede conectar a cualquier servidor que desee, entonces un terminal web se dibuja usando Xterm JS, este terminal es conectado al UUT real usando el socket que se identifica por el número de serie del mainboard.",
}

export {
    FIRST_PARAGRAPH,
    SECOND_PARAGRAPH,
    THIRD_PARAGRAPH,
    FOURTH_PARAGRAPH,
    FIFTH_PARAGRAPH,
}