import Main from "../../../assets/imgs/remine/new_transaction_clean.jpeg";
import NewTransactionView from "../../../assets/imgs/remine/new_transaction_view.jpeg";
import NewTransactionView2 from "../../../assets/imgs/remine/new_transaction_view_2.jpeg";

const RemineImgs = [
    Main,
    NewTransactionView,
    NewTransactionView2
];

export default RemineImgs;