import Carrousel from "../../../components/carrousel";
import strings from '../../../strings/portfolio';
import RectanglesList from "../../../components/rectanglesList";
import langsAndTechsStrings from '../../../strings/langsAndTechs';
import { COMPLEXITIES } from '../../../strings/complexities';
import paragraphs from '../../../strings/portfolio/nautilus';
import NautilusImgs from "./portfolio_imgs";
import Header from "../../../components/header";

const texts = strings();
const nautilusParagraphs = paragraphs();
const langsAndTechs = langsAndTechsStrings();

const DEVELOPED_IN = [
    langsAndTechs.VANILLA_JAVASCRIPT,
    langsAndTechs.VANILLA_PHP,
    langsAndTechs.SPA,
    langsAndTechs.NODEJS,
    langsAndTechs.XTERMJS,
    langsAndTechs.SOCKET_IO,
    langsAndTechs.BASH,
    langsAndTechs.MYSQL,
];

const Main = () => (
    <div className="main__portfolio longText">
        <Header text={texts.NAUTILUS} level={2} id="nautilus_portfolio" />
        <div>
            <Header text={texts.DEVELOPED_IN} level={3} />
            <RectanglesList listItems={DEVELOPED_IN} />
        </div>
        <Header text={texts.COMPLEXITY} level={3} />
        <RectanglesList listItems={[{ text: COMPLEXITIES.HIGH, link: "#" }]} />
        <Carrousel imgs={NautilusImgs} carrouselName="Nautilus" />
        <p className="longText">{nautilusParagraphs.FIRST_PARAGRAPH} <a href="https://buy.hpe.com/mx/es/servers/proliant-microserver/c/4237916?chatsrc=ot-es&jumpid=ps_qtjyx7w5cu_aid-520042859&gclid=Cj0KCQiA6Or_BRC_ARIsAPzuer_rWMS_b9sj77a9TaDUpExd_dYjeS4gxP2zIwXd5vyoWAZ7djP-WXUaAhB3EALw_wcB" rel="noreferrer" target="_blank" className="App-Link" >Microserver Gen 10</a>).</p>
        <p className="longText">{nautilusParagraphs.SECOND_PARAGRAPH}</p>
        <Header text={texts.UUT_PROCESS} level={3} />
        <p className="longText">{nautilusParagraphs.THIRD_PARAGRAPH}</p>
        <p className="longText">{nautilusParagraphs.FOURTH_PARAGRAPH}</p>
        <Header text={texts.USER_PROCESS} level={3} />
        <p className="longText">{nautilusParagraphs.FIFTH_PARAGRAPH}</p>
    </div>
);

export default Main;