import langsAndTechsStrings from "../../strings/langsAndTechs";
import RectanglesList from "../../components/rectanglesList";

const langAndTechs = langsAndTechsStrings();
export const mainStack = [
    langAndTechs.VANILLA_JAVASCRIPT,
    langAndTechs.TYPESCRIPT,
    langAndTechs.REACT,
    langAndTechs.NODEJS,
    langAndTechs.POSTGRESQL,
    langAndTechs.MYSQL,
    langAndTechs.MONGODB,
];

const MainStack = () => (
    <RectanglesList listItems={mainStack} />
);


export default MainStack;