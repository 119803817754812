import React from "react";
import Stack from "./stack";
import MainLinks from "../../components/mainLinks";
import AboutMe from "../../components/aboutMe";
import ChangeLanguage from "../../components/changeLanguage";
import WarningAboutSite from "../../components/warningAboutSite";

const Main = () => (
    <div className="app">
        <WarningAboutSite />
        <ChangeLanguage />
        <AboutMe />
        <main className="main">
            <Stack />
            <MainLinks />
        </main>
    </div>
);

export default Main;