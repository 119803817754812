import { WARNING_ABOUT_SITE, NEVER_SHOW_AGAIN, CLOSE_BUTTON } from './strings';
import navigatorLanguage from '../../utilities/language';
const lang = navigatorLanguage();

const strings = () => ({
    WARNING_ABOUT_SITE: WARNING_ABOUT_SITE[lang],
    NEVER_SHOW_AGAIN: NEVER_SHOW_AGAIN[lang],
    CLOSE_BUTTON: CLOSE_BUTTON[lang],
});

export default strings;