import PortfolioTexts from '../../../strings/portfolio';
import langsAndTechsStrings from '../../../strings/langsAndTechs';
import RemineImgs from './portfolio_imgs';
import strings from '../../../strings/portfolio/remine';
import RectanglesList from '../../../components/rectanglesList';
import Header from '../../../components/header';
import Carrousel from '../../../components/carrousel';
import { COMPLEXITIES } from '../../../strings/complexities';

const portfolioTexts = PortfolioTexts();
const langsAndTechs = langsAndTechsStrings();
const remineTexts = strings();

const DEVELOPED_IN = [
    langsAndTechs.VANILLA_JAVASCRIPT,
    langsAndTechs.JQUERY,
    langsAndTechs.REACT,
    langsAndTechs.REDUX,
    langsAndTechs.VANILLA_PHP,
    langsAndTechs.GOLANG,
    langsAndTechs.JAVA,
    langsAndTechs.POSTGRESQL,
    langsAndTechs.RABBITMQ,
    langsAndTechs.ITEXT,
    langsAndTechs.PHALCON,
    langsAndTechs.DOCKER,
    langsAndTechs.KUBERNETES,
    langsAndTechs.NODEJS,
    langsAndTechs.AWS,
    langsAndTechs.KOA,
    langsAndTechs.JWT,
];

const ReminePortfolio = () => (
    <div className="main__portfolio longText">
        <Header text={portfolioTexts.REMINE} level={2} id="remine_portfolio" />
        <Header text={portfolioTexts.DEVELOPED_IN} level={3} />
        <RectanglesList listItems={DEVELOPED_IN} />
        <Header text={portfolioTexts.COMPLEXITY} level={3} />
        <RectanglesList listItems={[{ text: COMPLEXITIES.HIGH, link: "#" }]} />
        <Carrousel imgs={RemineImgs} carrouselName="Remine" />
        <p>{remineTexts.FIRST_PARAGRAPH}</p>
        <p>{remineTexts.SECOND_PARAGRAPH}</p>
        <p>{remineTexts.THIRD_PARAGRAPH}</p>
        <p>{remineTexts.FOURTH_PARAGRAPH}</p>
    </div>
);

export default ReminePortfolio;