import navigatorLanguage from '../../utilities/language';
const lang = navigatorLanguage();

const LOW = {
    en: 'Low',
    es: 'Bajo',
};

const MEDIUM = {
    en: 'Medium',
    es: 'Medio',
}

const HIGH = {
    en: 'High',
    es: 'Alto',
}

export const COMPLEXITIES = {
    LOW: LOW[lang],
    MEDIUM: MEDIUM[lang],
    HIGH: HIGH[lang],
}