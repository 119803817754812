const FIRST_PARAGRAPH = {
    en: "This was an ERP system that I developed for a lawyers firm in Ciudad Juárez, the lawyers firm wanted a personalized ERP system from where they could manage all the resources of the firm.",
    es: "Este fue un ERP que trabajé para una firma de abogados en Ciudad Juárez, la firma de abogados quería un ERP personalizado donde pudieran administrar todos los recursos de la misma."
}

const SECOND_PARAGRAPH = {
    en: "This started in the first months of 2017 and I worked in this project until July of 2020. Unfortunately the pandemic forced to the firm to stop the development for economic reasons.",
    es: "Esto empezó en los primeros meses de 2017 y trabajé en este proyecto hasta julio de 2020. Desafortunadamente, la pandemia obligó a la firma a detener el desarrollo por razones económicas.",
}

const THIRD_PARAGRAPH = {
    en: "The project included a lot of modules and functions, we worked in basics CRUD modules, a module that generates documents (more precisely contracts, payment calendaries) automatically just taking the information saved on the database, and another several modules (statistics, reports, etc.) that were developed in Vue.js and Laravel.",
    es: "El proyecto incluyó muchos módulos y funciones, trabajé en módulos básicos de CRUD, un módulo que genera documentos (más precisamente contratos, calendarios de pagos) automáticamente tomando la información guardada en la base de datos, y otros módulos (estadísticas, reportes, etc.) que fueron desarrollados en Vue.js y Laravel.",
}

const FOURTH_PARAGRAPH = {
    en: "CRUD: The lawyers were able to save information about clients, while another lawyers that work on the same firm (this is protected by a roles protection middleware), can retrieve and modify the information saved. In the part of the system that stores the clients information the Google Maps API was implemented to save the exact address of the client, since the lawyers firm wanted to be able to generate statistics based on the addresses of their clients.",
    es: "CRUD: Los abogados pueden guardar información sobre clientes, mientras que otro abogado que trabaja en la misma firma (esto es protegida por un middleware de roles), puede recuperar y modificar la información guardada. En la parte del sistema que almacena la información de los clientes se implementó la API de Google Maps para guardar la dirección exacta del cliente, ya que la firma de abogados quería generar estadísticas basadas en las direcciones de sus clientes.",
}

const FIFTH_PARAGRAPH = {
    en: "Statistics: The lawyers are able to generate and view different statistics, the statistics can be viewed graphically.",
    es: "Estadísticas: Los abogados pueden generar y ver estadísticas diferentes, las estadísticas se pueden ver gráficamente.",
}

const SIXTH_PARAGRAPH = {
    en: "Support Module: If a user of IUSPak has any doubt about how to use it, they can go to the support module, there they can find an online manual, an offline manual (By downloading this manual in pdf format), even he can talk to a ChatBot developed using the BotMan PHP library.",
    es: "Módulo de soporte: Si un usuario de IUSPak tiene alguna duda sobre cómo usarlo, puede ir al módulo de soporte, allí encontrará un manual en línea, un manual offline (Descargando este manual en formato pdf), incluso puede hablar con un ChatBot desarrollado usando la librería BotMan PHP.",
}

const IUSPAK_MOBILE_TITLE = {
    en: "IUSPak Mobile",
    es: "IUSPak Movil"
}

const IUSPAK_MOBILE_FIRST_PARAGRAPH = {
    en: "IUSPAK Mobile was the Android/iOS app developed for the law firm, backend was the same as IUSPak, but the mobile app was developed in React Native.",
    es: "IUSPAK Movil fue la aplicación Android/iOS desarrollada para la firma de abogados, el backend era el mismo que IUSPak, pero la aplicación movil fue desarrollada en React Native.",
}

export {
    FIRST_PARAGRAPH,
    SECOND_PARAGRAPH,
    THIRD_PARAGRAPH,
    FOURTH_PARAGRAPH,
    FIFTH_PARAGRAPH,
    SIXTH_PARAGRAPH,
    IUSPAK_MOBILE_TITLE,
    IUSPAK_MOBILE_FIRST_PARAGRAPH
}