import { GREETING, MAIN_STACK, WORKED_WITH, PORTFOLIO, THE_LAB, GITHUB, LINKEDIN, CONTACT } from './strings';
import navigatorLanguage from '../../utilities/language';
const lang = navigatorLanguage();

const strings = () => ({
    BIO_GREETING: GREETING[lang],
    MAIN_STACK: MAIN_STACK[lang],
    WORKED_WITH: WORKED_WITH[lang],
    PORTFOLIO: PORTFOLIO[lang],
    THE_LAB: THE_LAB[lang],
    GITHUB: GITHUB[lang],
    LINKEDIN: LINKEDIN[lang],
    CONTACT: CONTACT[lang]
});

export default strings;