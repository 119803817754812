import { calculateYearsProgramming, calculateAge } from "./utils"
import navigatorLanguage from "../../utilities/language"

const language = navigatorLanguage();

const languages = {
    "en": [
        "Spanish",
        "English",
    ],
    "es": [
        "Español",
        "Inglés",
    ]
}

const hobbies = {
    "en": [
        "Video games",
        "Music",
        "Programming",
        "Reading",
    ],  
    "es": [
        "Videojuegos",
        "Música",
        "Programación",
        "Leer",
    ]
}

export const FakeProps = {
    name: "Ivan Fuentes",
    age: calculateAge(),
    location: "Ciudad Juarez, Chihuahua, Mexico",
    Languages: languages[language].join(", "),
    yearsProgramming: calculateYearsProgramming(),
    hobbies: hobbies[language].join(", "),
}