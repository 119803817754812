import PortfolioLinks from "../../../components/portfolioLinks";
import portfolioTexts from "../../../strings/portfolio/index";

const texts = portfolioTexts();

const MainText = () => (
    <div className="main__portfolio longText">
        {texts.FIRST_PARAGRAPH}
        <PortfolioLinks />
        <p>{texts.SECOND_PARAGRAPH}</p>
        <p>{texts.THIRD_PARAGRAPH}</p>
        <br></br>
    </div>
);

export default MainText;