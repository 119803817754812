import {
    DEVELOPED_IN,
    COMPLEXITY,
    UUT_PROCESS,
    USER_PROCESS,
    NAUTILUS,
    CHFINANCE,
    IUSPAK,
    MICROSOFT,
    OMS,
    REMINE,
    FIRST_PARAGRAPH,
    SECOND_PARAGRAPH,
    THIRD_PARAGRAPH,
} from "./shared/strings";
import navigatorLanguage from '../../utilities/language';
const lang = navigatorLanguage();

const strings = () => ({
    DEVELOPED_IN: DEVELOPED_IN[lang],
    COMPLEXITY: COMPLEXITY[lang],
    UUT_PROCESS: UUT_PROCESS[lang],
    USER_PROCESS: USER_PROCESS[lang],
    CHFINANCE: CHFINANCE[lang],
    IUSPAK: IUSPAK[lang],
    NAUTILUS: NAUTILUS[lang],
    MICROSOFT: MICROSOFT[lang],
    OMS: OMS[lang],
    REMINE: REMINE[lang],
    FIRST_PARAGRAPH: FIRST_PARAGRAPH[lang],
    SECOND_PARAGRAPH: SECOND_PARAGRAPH[lang],
    THIRD_PARAGRAPH: THIRD_PARAGRAPH[lang],
});

export default strings;