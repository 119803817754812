const GREETING = {
    en: "Hi there, I'm a full stack web apps developer",
    es: "Hola, soy un desarrollador web full stack"
}

const MAIN_STACK = {
    en: "Main stack:",
    es: "Stack principal:"
}

const WORKED_WITH = {
    en: "I have worked with:",
    es: "He trabajado con:"
}

const PORTFOLIO = {
    en: "Portfolio",
    es: "Portafolio"
}

const THE_LAB = {
    en: "The Lab",
    es: "El laboratorio"
}

const GITHUB = {
    en: "GitHub",
    es: "GitHub"
}

const LINKEDIN = {
    en: "Linkedin",
    es: "Linkedin"
}

const CONTACT = {
    en: "Contact",
    es: "Contacto"
}


export { GREETING, MAIN_STACK, WORKED_WITH, PORTFOLIO, THE_LAB, GITHUB, LINKEDIN, CONTACT };