const LinkButton = ({ textButton, url }) => {
    return (
        <div className="Back" id="button_back">
            <a
                className="App-link-List"
                href={url}
                rel="noopener noreferrer"
            >
                {textButton}
            </a>
        </div>
    )
}

export default LinkButton;