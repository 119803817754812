import langsAndTechsStrings from "../../strings/langsAndTechs";
import { mainStack } from "./mainStack";
import RectanglesList from "../../components/rectanglesList";

const langsAndTechs = langsAndTechsStrings();

let langAndTechsArray = [];

Object.keys(langsAndTechs).forEach((item) => {
    langAndTechsArray.push(langsAndTechs[item]);
});

const filteredLangAndTechsArray = langAndTechsArray.filter((item) => {
    return !mainStack.includes(item);
});

const WorkedWith = () => (
    <RectanglesList listItems={filteredLangAndTechsArray} />
);

export default WorkedWith;